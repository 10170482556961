import React from "react";
import Nav from "react-bootstrap/Nav";

function leagueLevels(league) {
    if (league.level_to >= 9999) {
        return `${league.level_from}+`;
    }
    return `${league.level_from}-${league.level_to}`;
}

function LeagueSelectorLeagues({ leagues, selectedLeague, onSelect }) {
    return (
        <Nav
            as="ul"
            className="nav nav-pills league-selector__nav-pills"
            activeKey={"" + selectedLeague}
            onSelect={(selectedLeague) => onSelect(parseInt(selectedLeague))}
        >
            {leagues.map((league, idx) => (
                <Nav.Item as="li" key={league.id}>
                    <Nav.Link eventKey={idx}>
                        <span>{league.name}</span> / {leagueLevels(league)}
                    </Nav.Link>
                </Nav.Item>
            ))}
        </Nav>
    );
}

export default function LeagueSelector({ leagues, selectedLeague, onSelect, isDark, isCentered }) {
    if (!leagues || leagues.length < 1) {
        return null;
    }

    return (
        <>
            <div
                className={
                    "league-selector league-selector--mobile" +
                    (isDark ? " league-selector--dark" : "") +
                    (isCentered ? " league-selector--centered" : "")
                }
            >
                <div className="league-selector__label">Лига:</div>
                <div className="league-selector__control">
                    <LeagueSelectorLeagues leagues={leagues} selectedLeague={selectedLeague} onSelect={onSelect} />
                </div>
            </div>
            <div
                className={
                    "league-selector league-selector--desktop" +
                    (isDark ? " league-selector--dark" : "") +
                    (isCentered ? " league-selector--centered" : "")
                }
            >
                <div className="league-selector__label">Лиги игроков:</div>
                <div className="league-selector__control">
                    <LeagueSelectorLeagues leagues={leagues} selectedLeague={selectedLeague} onSelect={onSelect} />
                </div>
            </div>
        </>
    );
}
