import { choosePlural } from "../lib/coreutils";

export default function MoreParticipantsButton({ numHiddenParticipants, size, isMobile, onClick }) {
    return (
        <div
            className={`more-participants-button more-participants-button--${isMobile ? "mobile" : "desktop"}`}
            style={{ width: size, height: size }}
            onClick={onClick}
        >
            <div className="h2">+{numHiddenParticipants}</div>
            <small className="fw-bold text-uppercase">
                {choosePlural(numHiddenParticipants, "участник,участника,участников")}
            </small>
        </div>
    );
}
