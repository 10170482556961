import Link from "next/link";

export default function HomeStats2({ stats }) {
    const russiaLabel = stats.stats_label || "все франчайзи";
    const inRussia = stats.show_russia_stats ? <div className="home-stats__russia">{russiaLabel}</div> : null;

    return (
        <div className="home-stats">
            <div className="container-fluid">
                <div className="home-stats__total-stats">
                    <div className="home-stats__cell">
                        <Link href={stats.num_games_url}>
                            <a>
                                <div className="home-stats__number">{stats.num_games}</div> игр сыграно
                            </a>
                        </Link>
                        {inRussia}
                    </div>
                    <div className="home-stats__cell">
                        <Link href={stats.num_players_url}>
                            <a>
                                <div className="home-stats__number">{stats.num_registered_players}</div> игроков
                            </a>
                        </Link>
                        {inRussia}
                    </div>
                    <div className="home-stats__cell">
                        <div className="home-stats__number">{stats.num_players_today}</div> игроков сегодня
                        {inRussia}
                    </div>
                </div>
            </div>
        </div>
    );
}
