import { urlToMedia } from "../../lib/content";

function PartnerLink({ partner }) {
    const image = <img src={urlToMedia(partner.image)} alt={partner.name} />;

    if (partner.url) {
        return (
            <span className="partners__link">
                <a href={partner.url} title={partner.name} target="_blank" rel="noreferrer">
                    {image}
                </a>
            </span>
        );
    }

    return <span className="partners__link">{image}</span>;
}

export default function Partners({ partners }) {
    if (partners.length == 0) {
        return null;
    }
    return (
        <div className="container-fluid">
            <div className="partners">
                <h3>
                    <b>Партнёры проекта</b>
                </h3>

                <div className="partners__links">
                    {partners.map((partner) => (
                        <PartnerLink partner={partner} key={partner.image} />
                    ))}
                </div>
            </div>
        </div>
    );
}
