import { useState } from "react";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import { enrollToGame } from "../../lib/api/games";
import { gtmOnEnrollment } from "../../lib/api/gtm";
import PrivacyNote from "../privacynote";
import PhoneNumberInput from "../controls/phonenumberinput";
import { getNameLabel } from "../../lib/coreutils";

export default function HomeEnrollment() {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [errors, setErrors] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);

    const playerErrors = errors.players ? errors.players[0] : {};

    const submitEnrollment = async (e) => {
        const response = await enrollToGame({
            gameId: null,
            players: [{ name, phone }],
        });
        const json = await response.json();
        if (response.ok) {
            setIsSuccess(true);
            gtmOnEnrollment(json);
            if (json.payment_url) {
                window.location.href = json.payment_url;
            }
        } else {
            setErrors(json);
        }
    };

    const hideSuccessModal = () => {
        setName("");
        setPhone("");
        setIsSuccess(false);
    };

    return (
        <>
            <div className="dark-plate home-enrollment">
                <div className="home-enrollment__bg"></div>

                <div className="container-fluid">
                    <h3>
                        <b>Записаться на игру</b>
                    </h3>

                    <form className="home-enrollment__form">
                        <div>
                            <Form.Control
                                type="text"
                                size="lg"
                                value={name}
                                placeholder={getNameLabel()}
                                isInvalid={playerErrors.name}
                                onChange={(e) => setName(e.target.value)}
                            />
                        </div>
                        <div>
                            <PhoneNumberInput
                                phone={phone}
                                placeholder="Телефон"
                                isInvalid={playerErrors.phone}
                                onChange={setPhone}
                                size="lg"
                            />
                        </div>
                        <div>
                            <button type="button" className="btn btn-primary btn-lg" onClick={submitEnrollment}>
                                Отправить заявку
                            </button>
                        </div>
                    </form>

                    <PrivacyNote className="home-enrollment__agreement" />
                </div>
            </div>
            {isSuccess && (
                <Modal show centered onHide={hideSuccessModal}>
                    <Modal.Header closeButton>
                        <Modal.Title as="h5">Запись на игру</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Вы успешно записались на игру.</h4>
                        <h5>Наш администратор свяжется с Вами.</h5>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="primary" onClick={hideSuccessModal}>
                            Закрыть
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}
        </>
    );
}
