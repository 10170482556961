import Avatar from "../avatar";
import HorizontalScroller from "../controls/horizontalscroller";

export default function HomeBirthdayPeople({ birthdayPeople }) {
    if (!birthdayPeople || birthdayPeople.length == 0) {
        return null;
    }
    return (
        <div className="container-fluid">
            <div className="home-birthday-people mb-n2">
                <div className="game-types mb-4">
                    <h3>
                        <b>День рождения сегодня</b>
                    </h3>
                </div>
                <HorizontalScroller scrollDelta={200} stateKey="home-birthday-people">
                    <div className="players">
                        {birthdayPeople.map((player) => (
                            <div className="players__card mb-2 border-0" key={player.id}>
                                <div className="players__card__image">
                                    <Avatar player={player} size={100} />
                                </div>
                                <div className="players__card__info">
                                    <div className="players__card__info__header">
                                        <div className="text-center mt-2 mb-0">
                                            {player.name}{" "}
                                            {player.age && (
                                                <span className="d-block d-sm-inline text-nowrap">({player.age})</span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </HorizontalScroller>
            </div>
        </div>
    );
}
