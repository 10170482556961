import { useState } from "react";

import Link from "next/link";

import Avatar from "../avatar";
import LeagueSelector from "../leagueselector";
import HomeAwards from "../homeawards";
import PlaceChange from "../placechange";
import { isMeet4USite } from "../../lib/coreutils";

function RatingRowMobile({ rating, place }) {
    const { player } = rating;
    return (
        <tr>
            <td>
                <Avatar player={player} size="56" className="home-tournament__avatar me-2" />
            </td>
            <td>
                <div>
                    <span className="home-tournament__position">{place}.</span>{" "}
                    <Link href={player.url}>
                        <a className="home-tournament__name">{player.name}</a>
                    </Link>
                    {!isMeet4USite() && <span className="home-tournament__level">[{player.level}]</span>}
                </div>
                <div className="home-tournament__description">{player.description}</div>
                <div className="home-tournament__stats">
                    {rating.num_games} <span>/</span> {rating.num_wins} <span>/</span> {rating.experience_gained}{" "}
                    <span>/</span> <PlaceChange change={rating.place_change} className="home-tournament__change" />
                </div>
            </td>
        </tr>
    );
}

function RatingRowDesktop({ rating, place }) {
    const { player } = rating;
    return (
        <tr>
            <td className="home-tournament__position">{place}</td>
            <td>
                <Avatar player={player} size="56" className="home-tournament__avatar" />
            </td>
            <td className="home-tournament__player">
                <Link href={player.url}>
                    <a className="home-tournament__name">{player.name}</a>
                </Link>
                {!isMeet4USite() && <span className="home-tournament__level">[{player.level}]</span>}
                <div className="home-tournament__description">{player.description}</div>
            </td>
            <td>{rating.num_games}</td>
            <td>{rating.num_wins}</td>
            <td>{rating.experience_gained}</td>
            <td>
                <PlaceChange change={rating.place_change} className="home-tournament__change" />
            </td>
        </tr>
    );
}

export default function HomeTournament({ tournament, ratings, leagues, awards }) {
    const initialLeague = leagues ? leagues.length - 1 : 0;
    const [selectedLeague, setSelectedLeague] = useState(initialLeague);

    if (!tournament || !leagues || !awards) {
        return null;
    }

    let leagueId = 0;
    if (leagues && leagues.length > 0) {
        const league = leagues[selectedLeague];
        leagueId = league.id;
    }

    const leagueAwards = awards.filter((award) => award.league == leagueId);
    const leagueRatings = ratings.filter((rating) => rating.league == leagueId);

    return (
        <div className="home-tournament">
            <div className="home-tournament__title">
                Турнир <a href={tournament.url}>{tournament.period_name}</a>
            </div>

            <div className="container-fluid">
                <LeagueSelector
                    leagues={leagues}
                    selectedLeague={selectedLeague}
                    onSelect={setSelectedLeague}
                    isDark
                    isCentered
                />
            </div>

            <div className="container-fluid home-tournament__awards">
                <HomeAwards awards={leagueAwards} />
            </div>

            <div className="d-mobile-only home-tournament--mobile">
                {leagueRatings.length > 0 && (
                    <>
                        <h3>
                            <b>Топ 5 игроков</b>
                        </h3>

                        <div className="home-tournament__legend">Игры / Побед / Рейтинг / Динамика</div>

                        <table>
                            <tbody>
                                {leagueRatings.slice(0, 5).map((rating, idx) => (
                                    <RatingRowMobile rating={rating} place={idx + 1} key={rating.place} />
                                ))}
                            </tbody>
                        </table>
                    </>
                )}

                <div className="home-tournament__rating-link">
                    <Link href={tournament.url}>
                        <a className="btn btn-primary">Полный рейтинг</a>
                    </Link>
                </div>
            </div>

            <div className="d-desktop-only home-tournament--desktop">
                <div className="container-fluid">
                    {leagueRatings.length > 0 && (
                        <>
                            <div className="home-tournament__table">
                                <table>
                                    <thead>
                                        <tr>
                                            <th colSpan="3" className="home-tournament__table__title">
                                                Топ 10 игроков
                                            </th>
                                            <th>Игры</th>
                                            <th>Побед</th>
                                            <th>Рейтинг</th>
                                            <th className="w-100"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leagueRatings.map((rating, idx) => (
                                            <RatingRowDesktop rating={rating} place={idx + 1} key={rating.place} />
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </>
                    )}

                    <div className="home-tournament__rating-link">
                        <Link href={tournament.url}>
                            <a className="btn btn-primary">Смотреть полный рейтинг</a>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
