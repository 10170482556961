export default function PlaceChange({ change, className }) {
    if (!change) {
        return null;
    }
    return (
        <span className={className + " " + className + (change > 0 ? "--up" : "--down")}>
            {change > 0 && "+"}
            {change}
        </span>
    );
}
