export default function HomeCustomBlock({ data }) {
    if (!data) {
        return null;
    }
    return (
        <div className="container-fluid home-custom-block">
            <h3>
                <a href={data.url} target="_blank" rel="noreferrer">{data.title}</a>
            </h3>
        </div>
    );
}
