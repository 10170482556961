import { useState } from "react";

import EmblaCarousel from "../embla";
import PhotoCarouselModal from "../photocarouselmodal";
import ThumbnailPicture from "../thumbnailpicture";

import { urlToMedia } from "../../lib/content";

const CAROUSEL_IMAGES_TO_SHOW = 12;

function HomeCrouselSlide({ imageData, index, setPhotoCarouselIndex }) {
    const { title, thumbnail, image } = imageData;

    const showCarousel = (e) => {
        e.preventDefault();
        setPhotoCarouselIndex(index);
    };

    return (
        <div className="embla__slide">
            <div className="embla__slide__inner">
                <a
                    href={urlToMedia(image)}
                    className="home-carousel__card"
                    title={title}
                    target="_blank"
                    rel="noreferrer"
                    onClick={showCarousel}
                >
                    <ThumbnailPicture thumbnail={thumbnail} width={null} height={250} alt={title} lazy />
                </a>
            </div>
        </div>
    );
}

export default function HomeCarousel({ images }) {
    const [photoCarouselIndex, setPhotoCarouselIndex] = useState(false);

    if (!images || images.length == 0) {
        return null;
    }

    let imagesToShow = images;

    while (imagesToShow.length < CAROUSEL_IMAGES_TO_SHOW) {
        imagesToShow = [...imagesToShow, ...images];
    }

    const slides = imagesToShow.map((image, idx) => (
        <HomeCrouselSlide imageData={image} index={idx} setPhotoCarouselIndex={setPhotoCarouselIndex} key={idx} />
    ));

    return (
        <>
            <div className="home-carousel">
                <EmblaCarousel slides={slides} viewportClass={"home-carousel__slides"} />
            </div>
            {photoCarouselIndex !== false && (
                <PhotoCarouselModal
                    photos={imagesToShow}
                    startIndex={photoCarouselIndex}
                    setPhotoCarouselIndex={setPhotoCarouselIndex}
                />
            )}
        </>
    );
}
