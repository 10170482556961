import Link from "next/link";
import EnrollmentButton from "../enrollmentbutton";
import PlacesLeftInfo from "../placesleftinfo";
import { useState } from "react";
import EnrollmentModal from "../enrollmentmodal";
import Avatar from "../avatar";
import { choosePlural, getItemsToShow } from "../../lib/coreutils";
import { getParticipantAvatarClassName } from "../../lib/content";
import MoreParticipantsButton from "../moreparticipantsbutton";

function HomePromoGameDetails({ game, promoGame }) {
    return (
        <div>
            <div className="h2 home-promo-game__title text-uppercase mb-2">
                <div className="text-primary text-preline fw-bold">{promoGame.home_title || "ВЕЧЕРИНКА"}</div>
                <div className="home-promo-game__month text-white text-preline fw-normal">
                    {promoGame.home_month || promoGame.month_year}
                </div>
            </div>
            <div className="px-2 px-sm-0">{game.feature}</div>

            <div className="my-4">
                <div>
                    <b className="text-white">{promoGame.date}</b> {promoGame.time}
                </div>
                <div>
                    <b className="text-white">Локация</b> {promoGame.place_name}
                </div>
            </div>

            <div className="d-mobile-only mb-3">
                <Link href={game.url}>
                    <a className="text-white fw-bold">Программа вечеринки</a>
                </Link>
            </div>
        </div>
    );
}

function HomePromoGameParticipation({ game }) {
    const numParticipants = game.participant_list.length;
    const [mobileInitialCount, mobileHiddenCount] = getItemsToShow(numParticipants, 8);
    const [desktopInitialCount, desktopHiddenCount] = getItemsToShow(numParticipants, 18);
    const [mobileParticipantsHidden, setMobileParticipantsHidden] = useState(mobileHiddenCount > 0);
    const [desktopParticipantsHidden, setDesktopParticipantsHidden] = useState(desktopHiddenCount > 0);
    const [showEnrollment, setShowEnrollment] = useState(false);

    const onEnrollment = () => {};

    return (
        <div className="home-promo-game__participation">
            <div className="home-promo-game__participants d-flex flex-wrap justify-content-center text-center my-3 my-sm-0">
                {game.participant_list.map((participant, idx) => (
                    <div
                        className={
                            "home-promo-game__participant" +
                            (mobileParticipantsHidden && idx + 1 > mobileInitialCount
                                ? " home-promo-game__participant--mobile-hidden"
                                : "") +
                            (desktopParticipantsHidden && idx + 1 > desktopInitialCount
                                ? " home-promo-game__participant--desktop-hidden"
                                : "")
                        }
                        key={participant.player.id}
                    >
                        <Avatar
                            player={participant.player}
                            size={62}
                            className={"avatar " + getParticipantAvatarClassName(participant)}
                        />
                    </div>
                ))}
                {mobileParticipantsHidden && (
                    <MoreParticipantsButton
                        numHiddenParticipants={mobileHiddenCount}
                        size={62}
                        isMobile
                        onClick={() => setMobileParticipantsHidden(false)}
                    />
                )}
                {desktopParticipantsHidden && (
                    <MoreParticipantsButton
                        numHiddenParticipants={desktopHiddenCount}
                        size={62}
                        onClick={() => setDesktopParticipantsHidden(false)}
                    />
                )}
            </div>
            <div className="d-sm-flex align-items-center justify-content-between px-2">
                <div className="d-desktop-only">
                    <Link href={game.url}>
                        <a className="text-white fw-bold">Программа вечеринки</a>
                    </Link>
                </div>
                <PlacesLeftInfo game={game} className="my-4 text-white fw-bold" />
                <EnrollmentButton
                    game={game}
                    onEnrollment={onEnrollment}
                    setShowEnrollment={setShowEnrollment}
                    className="px-4"
                />
            </div>
            {showEnrollment && (
                <EnrollmentModal game={game} onHide={() => setShowEnrollment(false)} onEnrollment={onEnrollment} />
            )}
        </div>
    );
}

export default function HomePromoGame({ promoGame, noContainer }) {
    if (!promoGame) {
        return null;
    }

    const game = promoGame.game;

    return (
        <div className={noContainer ? "" : "container-fluid"}>
            <div className="home-promo-game text-center text-sm-start text-muted">
                <HomePromoGameDetails game={game} promoGame={promoGame} />
                <HomePromoGameParticipation game={game} />
            </div>
        </div>
    );
}
