import Link from "next/link";
import Picture from "../picture";
import ThumbnailPicture from "../thumbnailpicture";
import { getSnippetContent } from "../../lib/content";

function CupLeader({ clan, experience }) {
    return (
        <Link href={clan.url}>
            <a className="w-100 text-center text-decoration-none" title={clan.name}>
                <div className="players-table__participant__avatar mx-auto">
                    <ThumbnailPicture thumbnail={clan.thumbnail} width={66} height={66} alt={clan.name} />
                </div>
                <div className="small my-1 line-clamp text-body">{clan.name}</div>
                <div className="text-muted small">{experience}</div>
            </a>
        </Link>
    );
}

export default function HomeDronovCup({ leaders, snippets }) {
    const title = getSnippetContent(snippets, "home-dronov-cup-title");
    const subTitle = getSnippetContent(snippets, "home-dronov-cup-subtitle");
    const description = getSnippetContent(snippets, "home-dronov-cup-description");
    return (
        <div className="container-fluid">
            <div className="dronov-cup">
                <div className="row">
                    <div className="col-12 col-sm-7 dronov-cup__first-panel">
                        <Picture
                            baseUrl="/img/dronov-cup/dronov-cup"
                            alt=""
                            className="dronov-cup__cup"
                            width={85}
                            height={85}
                            isPng
                        />
                        <Link href="/moscow/clantournament/">
                            <a className="dronov-cup__description flex-grow-1 mx-3 text-decoration-none">
                                <h3>{title}</h3>
                                <div className="dronov-cup__subtitle text-body">{subTitle}</div>
                                <div className="text-muted text-preline">{description}</div>
                            </a>
                        </Link>
                        <div className="d-desktop-only dronov-cup__chevron-right">
                            <img src="/img/icons/icon-chevron-gallery-right.svg" alt="" />
                        </div>
                    </div>
                    <div className="col-12 col-sm-5 dronov-cup__leaders">
                        <div className="fw-bold mt-2 mt-sm-0 mb-2 mb-sm-1">Текущие лидеры</div>
                        <div className="d-flex justify-content-between">
                            {leaders.map((rating, idx) => (
                                <CupLeader key={idx} clan={rating.clan} experience={rating.experience_gained} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
