import Link from "next/dist/client/link";
import HomeAwards from "../homeawards";
import GameTypeSelector from "../gametypeselector";
import { useState } from "react";

function getInitialGameType(tournaments) {
    if (!tournaments || tournaments.length == 0) {
        return null;
    }
    return tournaments[0].game_type.id;
}

export default function HomeTournament3({ tournaments }) {
    const [selectedGameType, setSelectedGameType] = useState(getInitialGameType(tournaments));

    let tournament = null;
    if (tournaments) {
        tournaments.forEach((t) => {
            if (t.game_type.id == selectedGameType) {
                tournament = t;
            }
        });
    }

    if (!tournament) {
        return null;
    }

    const gameTypes = tournaments.map((t) => t.game_type);

    return (
        <div className="home-tournament">
            <div className="home-tournament__title">
                Турнир <a href={tournament.url}>{tournament.period_name}</a>
            </div>

            <div className="container-fluid">
                <GameTypeSelector
                    gameTypes={gameTypes}
                    selectedGameType={selectedGameType}
                    centered
                    isDark
                    onSelect={setSelectedGameType}
                />
            </div>

            <div className="container-fluid home-tournament__awards">
                <HomeAwards awards={tournament.awards} />
            </div>

            <div className="d-mobile-only home-tournament--mobile">
                <div className="home-tournament__rating-link">
                    <Link href={tournament.url}>
                        <a className="btn btn-primary">Полный рейтинг</a>
                    </Link>
                </div>
            </div>
            <div className="d-desktop-only home-tournament--desktop">
                <div className="home-tournament__rating-link">
                    <Link href={tournament.url}>
                        <a className="btn btn-primary">Смотреть полный рейтинг</a>
                    </Link>
                </div>
            </div>
        </div>
    );
}
