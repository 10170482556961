import Nav from "react-bootstrap/Nav";

export default function GameTypeSelector({ gameTypes, selectedGameType, showAll, centered, isDark, onSelect }) {
    if (!gameTypes || gameTypes.length < 1) {
        return null;
    }

    return (
        <div
            className={
                "game-type-selector" +
                (isDark ? " game-type-selector--dark" : "") +
                (centered ? " game-type-selector--centered" : "")
            }
        >
            <div className="game-type-selector__control">
                <Nav
                    as="ul"
                    className="nav nav-pills game-type-selector__nav-pills"
                    activeKey={"" + (selectedGameType ? selectedGameType : "")}
                    onSelect={(selectedGameType) => onSelect(selectedGameType)}
                >
                    {showAll && (
                        <Nav.Item as="li">
                            <Nav.Link eventKey={""}>
                                <span>ВСЕ</span>
                            </Nav.Link>
                        </Nav.Item>
                    )}

                    {gameTypes.map((gameType, idx) => (
                        <Nav.Item as="li" key={gameType.id}>
                            <Nav.Link eventKey={gameType.id}>
                                <span>{gameType.name}</span>
                            </Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
            </div>
        </div>
    );
}
