import ThumbnailPicture from "./thumbnailpicture";

function HomeAward({ award }) {
    const { place, icon, title, description } = award;
    return (
        <div className={`home-award home-award--place-${place}`}>
            <div className="home-award__prize">
                <div className="home-award__wreath"></div>
                {icon ? (
                    <div className="home-award__icon">
                        <ThumbnailPicture thumbnail={icon} width={132} height={132} alt={title} lazy />
                    </div>
                ) : (
                    <div className="home-award__place">
                        <div>{place}</div>
                    </div>
                )}
            </div>
            <div className="home-award__details">
                <div className="home-award__place-name d-mobile-only">{place} МЕСТО приз</div>
                <div className="home-award__place-name d-desktop-only">Приз за {place} место!</div>
                <div className="home-award__title">{title}</div>
                <div className="home-award__description">{description}</div>
            </div>
        </div>
    );
}

export default function HomeAwards({ awards }) {
    if (awards.length == 0) {
        return null;
    }

    return (
        <div className="home-awards">
            {awards.map((award) => (
                <HomeAward award={award} key={`${award.place}/${award.league}`} />
            ))}
        </div>
    );
}
